import axios from 'axios';
// import { atom } from 'jotai';
import {
  Environment,
  Network,
  RecordSource,
  Store,
  RequestParameters,
  QueryResponseCache,
  Variables,
  GraphQLResponse,
  CacheConfig,
  UploadableMap,
} from 'relay-runtime';

export type RelayOptions =
  | {
      clientHttpEndpoint?: string;
      headers?: {
        'x-user'?: string;
      };
    }
  | undefined;

// import { environment as appEnvironment } from '../../environments/environment';

// const { mlGraphqlUrlInternal } = appEnvironment;

type ImportMetaEnv = {
  readonly VITE_ML_GRAPHQL_URL: string;
};

type ImportMeta = {
  readonly env: ImportMetaEnv;
};

const HTTP_INTERNAL_URL =
  typeof process !== 'undefined'
    ? process.env.ML_GRAPHQL_URL_INTERNAL ?? ''
    : '';
// console.log('HTTP_INTERNAL_URL', HTTP_INTERNAL_URL);
const IS_SERVER = typeof window === typeof undefined;
const CACHE_TTL = 5 * 1000; // 5 seconds, to resolve preloaded results

// export const progressAtom = atom(0);

// // We will use this to update the atom value from outside React components
// export let setProgressAtom: (value: number) => void = () => undefined;

// export const progressAtomWithSetter = atom(
//   (get) => get(progressAtom),
//   (get, set, update: number) => {
//     set(progressAtom, update);
//     setProgressAtom(update);
//   },
// );

// export const setProgressAtomSetter = (setter: (value: number) => void) => {
//   setProgressAtom = setter;
// };

export async function networkFetch(
  request: RequestParameters,
  variables: Variables,
  options: RelayOptions,
  uploadables?: UploadableMap | null,
): Promise<GraphQLResponse> {
  // console.log('UPLOADABLES', uploadables);
  // console.log('request', request);
  // console.log('variables', variables);
  const HTTP_EXTERNAL_URL =
    options?.clientHttpEndpoint ??
    ((import.meta as unknown as ImportMeta).env as unknown as ImportMetaEnv)
      ?.VITE_ML_GRAPHQL_URL ??
    '';
  // console.log('HTTP_EXTERNAL_URL', HTTP_EXTERNAL_URL);

  const { headers } = options ?? {};

  let body;
  if (uploadables && request.id) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    const formData = new FormData();
    // formData.append('query', request.id);
    // formData.append('variables', JSON.stringify(variables));

    formData.append(
      'operations',
      JSON.stringify({
        documentId: request.id,
        variables,
      }),
    );

    const map: { [key: string]: string[] } = {};
    Object.keys(uploadables).forEach((key, index) => {
      map[index] = [`variables.${key}`];
    });
    formData.append('map', JSON.stringify(map));

    Object.keys(uploadables).forEach((key, index) => {
      formData.append(`${index}`, uploadables[key]);
    });

    // Object.keys(uploadables).forEach((key) => {
    //   if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
    //     formData.append(key, uploadables[key]);
    //   }
    // });

    body = formData;
  } else {
    body = JSON.stringify({
      documentId: request.id,
      variables,
    });
  }

  // console.log('NEXTJS setting headers', headers);

  // const token = process.env.NEXT_PUBLIC_REACT_APP_GITHUB_AUTH_TOKEN;
  // if (token == null || token === "") {
  //   throw new Error(
  //     "This app requires a GitHub authentication token to be configured. See readme.md for setup details."
  //   );
  // }
  const fetchHeaders: HeadersInit = {
    Accept: 'application/json',
    // Authorization: `bearer ${token}`,
    // 'Content-Type': uploadables ? 'multipart/form-data' : 'application/json',
  };
  if (!uploadables) {
    fetchHeaders['Content-Type'] = 'application/json';
  }

  if (IS_SERVER) {
    fetchHeaders['x-user'] = headers?.['x-user'] ?? 'ano';
  }

  const HTTP_ENDPOINT = IS_SERVER ? HTTP_INTERNAL_URL : HTTP_EXTERNAL_URL;

  // const xUser = headers?.['x-user'] ?? 'ano';
  // console.log('xUser', xUser);
  // console.log('HTTP_ENDPOINT', HTTP_ENDPOINT);

  const resp = await axios.post(HTTP_ENDPOINT, body, {
    headers: fetchHeaders,
    // onDownloadProgress: (progressEvent) => {
    //   const { loaded, total } = progressEvent;
    //   console.log('loaded', loaded, 'total', total);
    //   if (total) {
    //     setProgressAtom((loaded / total) * 100);
    //   }
    // },
  });

  const json = resp.data;

  // const json = await resp.json();

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.error(json.errors);
    throw json.errors;
    // throw new Error(
    //   `Error fetching GraphQL query '${
    //     request.name
    //   }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
    //     json.errors,
    //   )}`,
    // );
  }

  return json;
}

export const responseCache: QueryResponseCache | null = IS_SERVER
  ? null
  : new QueryResponseCache({
      size: 100,
      ttl: CACHE_TTL,
    });

function createNetwork(options: RelayOptions) {
  async function fetchResponse(
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig,
    uploadables?: UploadableMap | null,
  ) {
    const isQuery = params.operationKind === 'query';
    const cacheKey = params.id ?? params.cacheID;
    const forceFetch = cacheConfig && cacheConfig.force;
    if (responseCache != null && isQuery && !forceFetch) {
      console.log('READ CACHE', cacheKey, variables);
      const fromCache = responseCache.get(cacheKey, variables);
      console.log('FROM CACHE', fromCache);
      if (fromCache != null) {
        return Promise.resolve(fromCache);
      }
    }

    return networkFetch(params, variables, options, uploadables);
  }

  const network = Network.create(fetchResponse);
  return network;
}

function createEnvironment(options: RelayOptions) {
  return new Environment({
    network: createNetwork(options),
    store: new Store(RecordSource.create()),
    isServer: IS_SERVER,
  });
}

export let environment: Environment;

export function getCurrentEnvironment(options: RelayOptions) {
  // console.log('getCurrentEnvironment options', options);
  if (IS_SERVER) {
    return createEnvironment(options);
  }
  if (!environment) {
    environment = createEnvironment(options);
  }

  return environment;
}
